@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  @apply bg-background-color text-lightest-gray-color antialiased font-sans;
}

main.main {
  @apply relative;
}

section h2 {
  @apply font-sans text-3xl lg:text-4xl text-lightest-gray-color font-medium tracking-tightest leading-9 lg:leading-52;
}

.productNavItem {
  @apply pr-28 bg-clear transition-colors duration-150 ease-in;
}

.productNavItem.monosign.highlighted {
  @apply bg-monosign-shade rounded-tl-sm rounded-bl-sm;
}

.productNavItem.monopam.highlighted {
  @apply bg-monopam-shade rounded-tl-sm rounded-bl-sm;
}

.productNavItem.monosync.highlighted {
  @apply bg-monosync-shade rounded-tl-sm rounded-bl-sm;
}

.productCard {
  @apply flex-grow overflow-hidden lg:h-530 flex flex-col;
}

.productCard p {
  @apply text-dark-text-color;
}

.productCard.monosign {
  @apply bg-lighter-green-color;
}

.productCard.monopam {
  @apply bg-monopam-card;
}

.productCard.monosync {
  @apply bg-monosync-card;
}

.productCardImage {
  @apply w-full h-80;
}

.monosign .productCardImage {
  background: url('/images/monosign-hero-image-horizontal.jpg') no-repeat 50%
    50%;
  background-size: cover;
}

.monopam .productCardImage {
  background: url('/images/monopam-hero-image.jpg') no-repeat 50% 50%;
  background-size: cover;
}

.monosync .productCardImage {
  background: url('/images/monosync-hero-image-horizontal.jpg') no-repeat 50%
    50%;
  background-size: cover;
}

.productCard.selected {
  @apply flex;
}

.productCard {
  @apply rounded-sm lg:rounded-none lg:rounded-tl-sm lg:rounded-bl-sm flex flex-col lg:hidden;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.industryNavItem.selected {
  @apply font-semibold underline;
}

.industryCard {
  @apply bg-white relative rounded-sm lg:rounded-none lg:rounded-tl-sm lg:rounded-bl-sm hidden overflow-hidden max-h-535 h-535;
}

.industryCard.selected {
  @apply flex flex-col justify-stretch;
}

span.success {
  @apply text-light-green-color;
}

span.failure {
  @apply text-red;
}

span.failure a {
  @apply text-white underline;
}

span.none {
  @apply text-white text-2xs;
}

.splide {
  @apply relative py-10;
}

.splide .splide__arrows {
  @apply bottom-0 right-10 absolute;
}

.splide .splide__arrow {
  @apply bg-clear;
}

.splide .splide__arrow svg {
  @apply w-6 h-3.5;
}

.splide .splide__arrows .splide__arrow--prev {
  @apply -left-10;
}

.splide .splide__arrows .splide__arrow--next {
  @apply -right-10;
}

.splide .splide__list {
  @apply flex flex-row gap-0 sm:gap-8;
}
